<style scoped>
.is-fullwidth {
  width: 100% !important;
}
</style>
<template>
  <div>

    <section class="section is-main-section" v-if="this.searchTermsCount === -1" >
        <b-message
            title="Click To Start"
            type="is-success"
            class="has-text-centered">

          <b-field style="max-width: 800px; margin: 0 auto;" class="is-centered">
            <multiselect
              v-model="selectedTypes"
              :options="[{'name': 'Domain', 'id': 'DOMAIN'}, {'name': 'Facebook Page', 'id': 'FACEBOOK_PAGE'}]"
              :multiple="true"
              :close-on-select="true"
              placeholder="Type"
              label="name"
              track-by="id">
            </multiselect>
          </b-field>

          <b-button type="is-primary" size="is-large" @click="start" :disabled="isLoading">Start!</b-button>
        </b-message>
    </section>
    <section class="section is-main-section" v-if="this.searchTermsCount === 0" >
        <b-message
            title="Success"
            type="is-success"
            aria-close-label="Close message">
            Well done! You are up-to-date.. Try again latter.
        </b-message>
    </section>
    <section class="section is-main-section" v-if="'id' in searchTerm && this.searchTermsCount > 0" >
      <b-loading v-model="isLoading"></b-loading>
      <b-navbar fixed-bottom>

        <template #end>
            <b-navbar-item tag="div">
              <span> Processed (Current Session): {{ counter }}</span>

            </b-navbar-item>
            <b-navbar-item tag="div">
                <div class="buttons">
                  <b-button class="is-primary m-1" @click="save" :disabled="isLoading">
                    Save
                  </b-button>
                </div>
            </b-navbar-item>
        </template>
      </b-navbar>

      <div class="tile is-ancestor">
        <div class="tile is-vertical is-12">
          <div class="tile">
            <div class="tile is-parent">
              <div class="tile is-child notification is-info ">
                <p class="title">(1 of {{ searchTermsCount }}) {{ searchTerm.type }} -
                  <a @click="openUrl(`https://naples.cloud.spectretechnology.io/admin/domain/searchterm/?q=${searchTerm.value}`)">
                    {{ searchTerm.value }}
                  </a>
                </p>
                <div class="subtitle">

                  <div class="columns mt-6">
                    <div class="column is-four-fifths">
                      <b-checkbox :indeterminate="searchTerm.is_active === null" v-model="searchTerm.is_active">
                        Is Active
                      </b-checkbox>

                      <table class="table is-striped is-hoverable mt-6">
                        <thead>
                          <tr>
                            <th>
                              Position Type <b-button class="is-small" @click="setPositionAs('q_url')">q</b-button> | <b-button class="is-small" @click="setPositionAs('minus_2_plus')">-2(+)</b-button>
                            </th>
                            <th>
                              Param Key
                            </th>
                            <th>
                              Path Position
                            </th>
                            <th>
                              Slug Separator
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(kp, rowId) in searchTerm.keyword_positions" v-bind:key="`kp-${rowId}`">
                            <td>
                              <b-select v-model="kp['position_type']">
                                <option value="PARAMS">Params</option>
                                <option value="PATH">Path</option>
                              </b-select>
                            </td>
                            <td>
                              <b-input aria-placeholder="Param Key" v-model="kp['param_key']"
                                       :disabled="kp['position_type'] !== 'PARAMS'" />
                            </td>
                            <td>
                              <b-input aria-placeholder="Path Position" v-model="kp['path_position']"
                                       :disabled="kp['position_type'] !== 'PATH'" />
                            </td>
                            <td>
                              <b-input aria-placeholder="Slug Separator" v-model="kp['slug_separator']"
                                       :disabled="kp['position_type'] !== 'PATH'" />
                            </td>
                            <td>
                              <b-button icon-left="close" type="is-danger" @click="removeKeywordPosition(rowId);"
                                        :disabled="searchTerm.keyword_positions.length===1"></b-button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="5">
                              <b-button icon-left="check" type="is-success"
                                        @click="addEmptyKeywordPosition(true);"></b-button>
                            </td>
                          </tr>
                        </tbody>

                      </table>

                      All Keywords: {{ extractAllKeywords() }}
                    </div>
                    <div class="column">
                      <b-select
                        multiple
                        native-size="17"
                        v-model="selectedEnableCountries">
                        <option :value="ec.id" v-for="ec in enableCountries" v-bind:key="`ec-${ec.id}`">
                          {{ ec.country.code }} ({{ ec.country.name }}) - {{ ec.language.code }} ({{ ec.language.name }})
                        </option>
                      </b-select>
                    </div>
                  </div>

                  <b-field class="is-fullwidth">
                      <b-input placeholder="Url..."
                          icon="web"
                          :value="searchTerm.sample_url_value" :disabled="true"

                          class="is-fullwidth"
                      />
                      <div class="control">
                        <b-button type="is-primary" label="Open" @click="openUrl(searchTerm.sample_url_value)">
                        </b-button>
                      </div>
                  </b-field>

                 <b-field class="is-fullwidth">
                      <b-input placeholder="Url..."
                          icon="facebook"
                          :value="searchTerm['ads_library_url']"
                          class="is-fullwidth"
                               :disabled="true"
                      />
                      <div class="control">
                        <b-button type="is-primary" label="Open" @click="openUrl(searchTerm['ads_library_url'].replace('country=US', 'country=ALL'))">
                        </b-button>
                      </div>
                  </b-field>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// import Vue from "vue";
// import CardComponent from '@/components/CardComponent';
// import {mapState} from "vuex";
import {
  naplesSearchTermsService,
  naplesNoActivatedSearchTermService,
} from "@/services";
import {mapState} from "vuex";
import {notificatorUtils} from "@/helpers/notificator-utils";
import Multiselect from 'vue-multiselect'
import {naplesEnableCountriesService} from "@/services/naples-enable-countries.service";
import vue from "vue";
import numeraljs from "numeraljs";
import moment from "moment";
// import Vue from "vue";

export default {
  name: 'SearchTermsPage',

  mounted() {
    this.getCountries()
  },

  mixins: [
    notificatorUtils
  ],
  components: {
    Multiselect
  },

  data() {
    return {
      loadUrl: '',

      searchTermsCount: -1,
      searchTerm: {},

      selectedTypes: [],

      isLoading: false,

      counter: 0,
      enableCountries: [],
      selectedEnableCountries: [],
    }
  },

  computed: {
    ...mapState('theme', ['userName']),
  },

  methods: {

    start() {
      this.loadData()
    },

    getCountries(){
      naplesEnableCountriesService.getAll().then(
            response => {
              this.enableCountries = response.data['results']
            })
    },

    loadData() {
      let params = {};

      this.searchTerm = {};

      if(this.selectedTypes.length > 0){
        params['type__in'] = this.selectedTypes.map((element) => { return element.id }).join(',');
      }

      params['created_on_datetime__lt'] = moment().utc().subtract(1, 'hours').format('YYYY-MM-DD HH:mm:ss'),

      this.isLoading = true;
      naplesNoActivatedSearchTermService.get(params).then(
            response => {
              let that = this;

              that.isLoading = false;

              if(response.data['results']){
                this.searchTermsCount = response.data['count'];

                if(this.searchTermsCount === 0){
                  return;
                }

                this.searchTerm = response.data['results'][0];
                this.selectedEnableCountries = this.searchTerm['enabled_countries'];
                this.counter = this.counter + 1;
                this.addEmptyKeywordPosition();

              }else{
                alert('All search terms are processed. Well done!');
              }
            },
            error =>
            {
              alert('An Error Occurred 2');
              console.error(error);
              this.isLoading = false;
            });
    },

    addEmptyKeywordPosition(force){
      force = force || false;

      if(!force && this.searchTerm.keyword_positions.length > 0){
        return;
      }

      vue.set(this.searchTerm.keyword_positions, this.searchTerm.keyword_positions.length,
          this.generateEmptyKeywordPosition())
    },

    removeKeywordPosition(rowId){
      vue.delete(this.searchTerm.keyword_positions, rowId)
    },

    generateEmptyKeywordPosition(){
      return Object.assign({}, {
        'position_type': '',
        'param_key': '',
        'path_position': '',
        'slug_separator': '',
      });
    },

    openUrl(url){
      window.open(
        url,
        "DescriptiveWindowName",
        "resizable,scrollbars,status,top=400"
      );
    },

    save() {

      let params = {
        'is_active': this.searchTerm.is_active,
      };

      if(this.searchTerm.is_active === null){
        return alert('Please select if this search term must be active or not.');
      }

      if(this.searchTerm.is_active === true){

        params['keyword_positions'] = this.searchTerm.keyword_positions;
        params['search_term_countries'] = this.selectedEnableCountries.map((item) => { return {'enabled_country': item}});

        if(this.searchTerm.keyword_positions.length === 0){
          return alert('You must provide at least one keyword position.')
        }

        for(let i in this.searchTerm.keyword_positions){

          let item = this.searchTerm.keyword_positions[i];

          if(item['position_type']==='PARAMS'){

            item['path_position'] = null;
            item['slug_separator'] = null;

            if(!item['param_key'] || item['param_key'] === ''){
              return alert('You must provide a param key when the keyword position type is Params');
            }

          }else if(item['position_type']==='PATH'){

            item['param_key'] = null;

            if(!item['path_position'] || item['path_position'] === ''){
              return alert('You must provide a path position when the keyword position type is Path');
            }
            if(!item['slug_separator'] || item['slug_separator'] === ''){
              return alert('You must provide a slug separator when the keyword position type is Path');
            }

          }else{
            return alert('You must select keyword position type. Alternative you could delete the row.')
          }
        }
      }

      naplesSearchTermsService.patch(this.searchTerm.id, params).then(()=>{
        this.$buefy.toast.open('Saved!')
      })

      this.next();
    },

    next(){
      this.loadData();
    },

    extractKeyword(keywordPositionsRowId){

      let pos = this.searchTerm.keyword_positions[keywordPositionsRowId],
          url,
          paths = '',
          keyword='';

      try{
        if(this.searchTerm.sample_url_value.indexOf('http') === -1){
          this.searchTerm.sample_url_value = 'https://' + this.searchTerm.sample_url_value;
        }
        url = new URL(this.searchTerm.sample_url_value);
      }
      catch (e) {
        alert('SearchTerm: ' + this.searchTerm.id + ', has a wrong url: ' + this.searchTerm.sample_url_value + ', delete or fix from Naples.');
      }

      if(pos['position_type']==='PARAMS'){

        if(!pos['param_key']){
          return '';
        }

        return url.searchParams.get(pos['param_key']);

      }else if(pos['position_type']==='PATH'){

        if(!pos['path_position']){
          return '';
        }

        paths = (url.pathname).split('/')
        keyword = paths[paths.length + numeraljs(pos['path_position']).value()]

        if(pos['slug_separator'] && pos['slug_separator'] !== ''){
          keyword = keyword.replaceAll(pos['slug_separator'], ' ')
        }

        return keyword;

      }

    },

    extractAllKeywords(){
      let keywords = [];

      for(let i in this.searchTerm.keyword_positions){

        keywords.push(this.extractKeyword(i));

      }

      return keywords.join(', ');
    },

    setPositionAs(typeCode){
      let index = this.searchTerm.keyword_positions.length - 1;

      if(typeCode==='q_url'){
        this.searchTerm.keyword_positions[index].position_type = 'PARAMS';
        this.searchTerm.keyword_positions[index].param_key = 'q';

      }else if(typeCode==='minus_2_plus'){
        this.searchTerm.keyword_positions[index].position_type = 'PATH';
        this.searchTerm.keyword_positions[index].path_position = '-2';
        this.searchTerm.keyword_positions[index].slug_separator = '+';
      }

    }
  }
}
</script>
