<template>
  <footer :show="true" class="footer">
    <div class="container-fluid">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <div class="footer-copyright">
              <b>&copy; {{ year }}, Pytech.ai</b> &mdash; Mozart
              <span class="tag">v0.0.1</span>
            </div>
          </div>
        </div>
        <div class="level-right">
          <div class="level-item">
            <div class="logo">
              <a href="https://mozart.cloud.spectretechnology.io">
                <img src="../assets/logo.png" alt="mozart.cloud.spectretechnology.io" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import dayjs from 'dayjs'
import { mapState } from 'vuex'

export default {
  name: 'FooterBar',
  computed: {
    year () {
      return dayjs().year()
    },
    ...mapState('theme', ['isFooterBarVisible'])
  }
}
</script>
