<style scoped>
table.table td .image {
  width: unset !important;
  height: unset !important;
}
</style>
<template>
  <div>
    <section class="section is-main-section">
        <dependant-multi-filter
          v-model="selectedMultiFilter"
          partners-enabled
          :filterPartners = "filterPartners"
          @filter="filter"
        />

        <b-table striped :data="rows">

          <b-table-column label="Partners"   v-slot="props">
            {{ props.row['agency_feeds'].map(e => e.partner_set.map(e => e.code).join(", ")).join() }}
          </b-table-column>

          <b-table-column field="found_keyword_network.network.code" label="Network" v-slot="props">
            {{ props.row['found_keyword_network']['network']['code'] }}
          </b-table-column>

          <b-table-column field="found_keyword_network.found_keyword.keyword.name" label="Keyword" v-slot="props">
            <span>{{ props.row['found_keyword_network']['found_keyword']['keyword']['name'] }}</span> *({{ props.row['found_keyword_network']['found_keyword']['country']['code'] }})
          </b-table-column>

          <b-table-column field="found_keyword_network.found_keyword.keyword.name" label="Related" v-slot="props">
            <pre>{{ props.row['json_url_extra_parameters'] && props.row['json_url_extra_parameters']['sqs'] ? props.row['json_url_extra_parameters']['sqs'].split(',').join("\n") : '' }}</pre>
          </b-table-column>

          <b-table-column field="found_keyword_network.found_keyword.keyword.name" label="Images" v-slot="props">
            <div v-if="props.row.images.length > 0">
              <b>(custom)</b><br />
              <div v-for="img in props.row.images" v-bind:key="`fbaTemplate-custom-${props.row.id}-${img.id}`">
                <b-image :src="`https://api.mozart.cloud.spectretechnology.io/api/proxy/picasso/image/image-opener-public/?mode=full_image&image_hash_unique=${img.image_hash_unique}`" style="max-width: 150px;"></b-image>
              </div>
            </div>
            <div v-else>
              <div v-for="img in props.row.found_keyword_images" v-bind:key="`fki-custom-${props.row.id}-${img.id}`">
                <b-image :src="`https://api.mozart.cloud.spectretechnology.io/api/proxy/picasso/image/image-opener-public/?mode=full_image&image_hash_unique=${img.image_hash_unique}`" responsive style="max-width: 150px;"></b-image>
              </div>
            </div>
          </b-table-column>

          <b-table-column field="found_keyword_network.found_keyword.keyword.name" label="Creatives" v-slot="props">

            <div v-if="props.row.json_custom_settings && props.row.json_custom_settings.creatives && Object.keys(props.row.json_custom_settings.creatives).length > 0">
              <b>(custom)</b><br />
              <div v-for="fbaTemplate in props.row.json_custom_settings.creatives" v-bind:key="`fbaTemplate-custom-${props.row.id}-${fbaTemplate.id}`">
                <b> Headline:</b>
                <span>{{ fbaTemplate.headline }}</span>
                <b> Message:</b>
                <span>{{ fbaTemplate.message }}</span>
                <b> Description:</b>
                <span>{{ fbaTemplate.description }}</span>
              </div>
            </div>

            <div v-else>
              <div v-for="fbaTemplate in fbaTemplates.filter(e => e.language === props.row['found_keyword_network']['found_keyword']['country']['default_language']['id'])" v-bind:key="`fbaTemplate-${props.row.id}-${fbaTemplate.id}`">
                  <b> Headline:</b>
                  <span>{{ fbaTemplate.headline }}</span>
                  <b> Message:</b>
                  <span>{{ fbaTemplate.message }}</span>
                  <b> Description:</b>
                  <span>{{ fbaTemplate.description }}</span>
              </div>
            </div>

          </b-table-column>

          <b-table-column label="Actions" v-slot="props">
            <b-button v-if="props.row.id">Set Domain</b-button>
          </b-table-column>

          <template #empty>
            <div class="has-text-centered">No records</div>
          </template>

        </b-table>


    </section>


  </div>
</template>

<script>
import {mapState} from "vuex";
import {
  creationFoundKeywordNetworkUserService,
} from "@/services";
import {numberUtils} from "@/helpers/number-utils";
import {permissionsUtils} from "@/helpers/permissions-utils";
import DependantMultiFilter from "@/components/Filter/DependantMultiFilter";
import {creationCreativeTemplateFBAService} from "@/services/creation-creative-template-fba.service";

export default {
  name: 'ProductRequestPage',

  mixins: [
      numberUtils,
      permissionsUtils,
  ],

  mounted() {
    this.loadVuex();
  },

  components: {
    DependantMultiFilter,
  },

  data() {
    return {

      rows: [],
      fbaTemplates: [],

      // Constants
      PARKED_DOMAIN: 'b9c83c1f-bf55-491b-ae07-26fb8b54c54c',

      // Variables
      selectedMultiFilter: {},
    }
  },

  computed: {
    ...mapState([
      'networks',
      'agencyFeeds',
      'countries',
      'subdomainLocales',
      'partners',
      'products',
    ]),

    isLoading() {
      return [this.networks.loading, this.subdomainLocales.loading, this.partners.loading, this.products.loading].includes(true)
    },
    feeds() {
      if(!this.selectedMultiFilter['partners']){
        return [];
      }
      let a = this.selectedMultiFilter['partners'].map((e) => {
        return e.agency_feed.feed;
      });

      return [...new Set(a)];
    },

  },

  methods: {

    loadVuex() {
      let that = this;
      this.getTemplates(() => {
        that.filter()
      })

      if(!this.networks.allAvailableNetworks.length) this.$store.dispatch('networks/getAllAvailableNetworks');
      if(!this.agencyFeeds.all.length) this.$store.dispatch('agencyFeeds/getAll');
      if(!this.countries.allFullList.length) this.$store.dispatch('countries/getAllFullList');
    },

    filterPartners(e){
      return e.agency_feed.feed === this.PARKED_DOMAIN;
    },

    filterNetworks(e){
      return e.code === 'FBA';
    },

    filterProducts(e){
      return e.partner === '289d20d7-0384-4e9f-8fa7-5d74b209bbf7';
    },

    filter(){
      let that = this;
      creationFoundKeywordNetworkUserService.get({
        'feed__in': this.PARKED_DOMAIN,
        'is_active': true
      }).then(response => {
         that.rows = response.data['results']
      })
    },
    getTemplates(c){
      let that = this;

        c = c || false;
      creationCreativeTemplateFBAService.get({
        'is_default': true,
        'is_multibranding': false,
        'is_active': true,
      }).then(response => {
        that.fbaTemplates = response.data['results']
        if(c){
          c();
        }
      })
    }
  },

}
</script>
