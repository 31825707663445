<template>
    <section class="section is-main-section">
        <form @submit.prevent="submit">
            
            <b-loading v-model="users.loadingMarketing" />
            <p v-if="errors.length" class="notification is-danger content is-light mb-3" style="max-width: 700px">
                <ul class="m-0">
                    <li v-for="(er, index) in errors" :key="index">{{ er }}</li>
                </ul>
            </p>
            
            <div class="card">
                <div class="card-content py-3">
                    <b-field label="Firstname">
                        <b-input
                            name="first_name"
                            type="text"
                            v-model="first_name"
                            placeholder="Firstname"
                            required>
                        </b-input>
                    </b-field>

                    <b-field label="Lastname">
                        <b-input
                            name="last_name"
                            type="text"
                            v-model="last_name"
                            placeholder="Last_name"
                            required>
                        </b-input>
                    </b-field>

                    <b-field label="Email">
                        <b-input
                            name="email"
                            type="email"
                            v-model="email"
                            placeholder="Email"
                            required>
                        </b-input>
                    </b-field>

                    <b-field label="Password"
                        :type="!isMatchedPassword.empty && isMatchedPassword.error ? 'is-danger' : null"
                        :message="!isMatchedPassword.empty && isMatchedPassword.error ? 'Password don\'t match' : null"
                    >
                        <b-input
                            name="password"
                            type="password"
                            v-model="password"
                            placeholder="Password"
                            minlength="8"
                            required>
                        </b-input>
                    </b-field>

                    <b-field label="Repeat Password"
                        :type="!isMatchedPassword.empty && isMatchedPassword.error ? 'is-danger' : null"
                        :message="!isMatchedPassword.empty && isMatchedPassword.error ? 'Password don\'t match' : null"
                    >
                        <b-input
                            name="repeatPassword"
                            type="password"
                            v-model="repeatPassword"
                            placeholder="Repeat Password"
                            minlength="8"
                            required>
                        </b-input>
                    </b-field>

                    <b-field label="Role">
                        <b-checkbox v-model="is_organization_admin">Is Admin</b-checkbox>
                        <Tooltip label="admins can create new users and have the same permissions as yourself" />
                    </b-field>

                   <label class="label">Access</label>
                    <div class="field has-addons">
                        <multiselect
                            v-model="access.agencyFeeds"
                            :options="agencyFeeds.all"
                            :multiple="true"
                            :close-on-select="true"
                            placeholder="Agency Feeds"
                            :label="getFieldCodeLabel()"
                            track-by="id">
                        </multiselect>
                        <multiselect
                            v-model="access.networks"
                            :options="networks.all"
                            :multiple="true"
                            :close-on-select="true"
                            placeholder="Networks"
                            label="name"
                            track-by="id">
                        </multiselect>
                    </div>
                    <b-field>
                        <p v-if="!access.networks.length || !access.agencyFeeds.length" class="notification p-2">Select at least one Network and Agency feed to set the profit</p>
                        <div v-for="(agencyFeed, ai) in access.agencyFeeds" :key="ai">
                            <div v-if="access.networks.length">
                                <span> {{ agencyFeed[getFieldCodeLabel()]  + ' &lt;&gt; ' + listNetworks }}</span>
                                <b-slider size="is-medium" indicator @input="val => network_agency_feed_profit_shares[agencyFeed.id] = val" :tooltip="false" format="percent" :value="40" />
                            </div>
                        </div>
                    </b-field>
                </div>
                <div class="card-footer px-5 py-2 is-justify-content-space-between">
                    <b-button type="is-info" @click="$router.back()">Back</b-button>
                    <b-button type="is-success" @click="submit">Save</b-button>
                </div>
            </div>
        </form>
    </section>
</template>

<script>
import Tooltip from '@/components/Tooltip';
import Multiselect from 'vue-multiselect'
import {permissionsUtils} from "@/helpers/permissions-utils";
import { mapState } from 'vuex'
export default {
    name: 'UsersPageNew',

    data() {
        return {
            first_name: '',
            last_name: '',
            email: '',
            password: '',
            repeatPassword: '',
            is_organization_admin: false,
            profit_share: 0,
            network_agency_feed_profit_shares: {},
            access: {
                networks: [],
                agencyFeeds: [],
            },
            errors: [],
        }
    },

    mixins: [
        permissionsUtils,
    ],

    components: {
        Multiselect,
        Tooltip,
    },

    created() {
        if(!this.agencyFeeds.all.length) this.$store.dispatch('agencyFeeds/getAll')
        if(!this.networks.all.length) this.$store.dispatch('networks/getAll')
    },

    computed: {

        ...mapState([
            'users',
            'agencyFeeds',
            'networks',
        ]),

        isMatchedPassword() {
            const { password, repeatPassword } = this
            if(password && repeatPassword) {
                if(password === repeatPassword) {
                    return { empty: false, error: false }
                }
                return { empty: false, error: true }
            }
            return { empty: true, error: true }
        },

        listNetworks() {
            return this.access.networks.map(n => n.code)
        },

    },

    methods: {
        submit() {
            this.errors = []

            //check
            if(!this.isValidForm()) return
                        
            //parse profit
            const network_agency_feed_profit_shares = this.parseProfit()
            
            //save
            const { first_name, last_name, email, password, is_organization_admin } = this
            const payload = {
                first_name,
                last_name,
                email,
                password,
                is_organization_admin,
                network_agency_feed_profit_shares,
            }

            this.$store.dispatch('users/saveUserMarketingAgent', payload)
                        .then(() => {
                            //notify the user
                            this.$buefy.snackbar.open({
                                duration: 10000,
                                message: `
                                    User ${ this.first_name } ${ this.last_name } has been successfully created.<br/><br/>
                                    Find login details below:<br />
                                    website: https://mozart.cloud.spectretechnology.io<br />
                                    username: ${this.email}<br />
                                    password: ${ this.password }`,
                                actionText: 'Ok',
                                queue: false,
                            });
                            
                            //redirect to agency manager
                            this.$router.push({ name: 'users' })
                            this.$router.go();
                        })
                        .catch(err => {
                            const errorsList = err?.response?.data?.details || []
                            if(errorsList) {
                                this.errors = Array.isArray(errorsList) ? errorsList : [errorsList]
                                return;
                            }                 
                            alert('An error occurred while creating a new user, please stop now, and contact IT Support.');
                        })
        },

        isValidForm() {
            const { first_name, last_name, email, password } = this
        
            if(first_name && last_name && email && password) {
                return !this.isMatchedPassword.error;
            }
            return false
        },

        parseProfit() {
            let profit_share = []
           for(const key in this.network_agency_feed_profit_shares) {
                const agency_feed_id = key
                const networks_id = this.access.networks.map(n => n.id)
                const isSelectedAgencyFeed = this.access.agencyFeeds.find(a => a.id == agency_feed_id)
                if(isSelectedAgencyFeed) {
                    profit_share.push({
                        agency_feed_id,
                        networks_id,
                        profit_share: this.network_agency_feed_profit_shares[key],
                    })
                }
            }
            return profit_share;
        },
    }
}
</script>

<style lang="scss" scoped>
.card {
    max-width: 700px;
    .card-header {
        border-top: 1px solid rgba(24, 28, 33, 0.06);
    }
}
</style>
