<template>
  <aside class="aside is-placed-left is-expanded" style="overflow: auto;">
    <aside-tools :is-main-menu="true">
      <span slot="label">
        <span class="is-hidden-desktop">Mozart</span>
        <img src="../assets/logo.png" alt="mozart.cloud.spectretechnology.io" class="is-hidden-mobile" style="margin-left: -.75rem; max-width: 3rem;" />
      </span>
    </aside-tools>
    <div class="menu is-menu-main">
      <template v-for="(menuItems, index) in menuFiltered">
        <p v-if="menuItems.length > 0" :key="index" class="menu-label">
          {{ index }}
        </p>
        <aside-menu-list
            v-if="menuItems.length > 0"
            :key="`aside-menu-list-${index}`"
            :menu="menuItems"
            @menu-click="menuClick"
        />
      </template>
    </div>
  </aside>
</template>

<script>
import AsideTools from '@/components/AsideTools'
import AsideMenuList from '@/components/AsideMenuList'
import {mapState} from "vuex";

export default {
  name: 'AsideMenu',
  components: { AsideTools, AsideMenuList },
  props: {
    menu: {
      type: Object,
      default: () => {}
    }
  },
  created() {
    this.$store.dispatch('theme/getUserDetails');
  },
  computed: {
    // isAsideVisible: function () {return true }
    ...mapState(['theme']),
    menuFiltered() {

      let menu = Object.assign({}, this.menu);

      for(let label in menu) {

        menu[label] = menu[label].filter(item=>{
          return this.visibleTo(item);
        })

      }

      return menu;
    }
  },
  methods: {
    // menuClick (item) {
    menuClick () {
      //
    },
    visibleTo (item){
      let canShow = false;

      for(let i in item.visibleTo){
        if(this.theme.userGroups.indexOf(item.visibleTo[i]) > -1){
          canShow = true;
        }
      }

      if(canShow) {
        for(let i in item.hideFrom){
          if(this.theme.userGroups.indexOf(item.hideFrom[i]) > -1) canShow = false;
        }
      }

      return canShow;
    }
  }
}
</script>
