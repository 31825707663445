<template>
  <div>
  <div v-for="account in accounts" v-bind:key="`account-${account.id}`" style="border: 1px solid #ccc; margin: 1rem; padding: 1rem;">
    {{ account.name }} - <a :href="`https://saul.cloud.spectretechnology.io/admin/administration/account/${account.id}/change/`" target="_saul">Saul</a>
    <br />
    Is Active {{ account.is_active ? 'Y' : 'N' }} |
    Ads on Review: {{ account.ads_on_review_count }} |
    Ads Live: {{account.ads_live_count}}/{{ account.ads_limit }}
  </div>
  </div>
</template>

<script>
export default {
  name: 'AccountsViewer',
  props: {
    accounts: {
      type: Array,
      default: () => []
    },
  }
}
</script>
