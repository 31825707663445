import axios from "axios";

export const notificatorUtils = {
    methods: {
        slackOutsourcingInfo(message) {
            axios.post('',
                {
                    text: message
                }, { headers: { 'Content-Type': 'application/x-www-form-urlencoded', } })
        },
        slackWarning(message) {
            axios.post('',
                {
                    text: message
                }, { headers: { 'Content-Type': 'application/x-www-form-urlencoded', } })
        },
    }
}
