<style lang="scss" scoped>

.image-unselected {
  border: 2px solid #FFF;
}

.image-selected {
  border: 2px solid blue;
}

.image-success {
  border: 2px solid darkgreen;
  opacity: 0.3;
}

.image-error {
  border: 2px solid red;
  opacity: 0.3;
}

.previously-selected {
  float: left;
  margin: 3px;
  cursor: pointer;

  img {
    width: 90px;
    height: 50px;
    background-color: #ddd;
  }
}

.previously-selected-modal {
  width: 100%;
  height: 100%;
  background-color: azure;
}

.modal-images {
  width: 210px;
  height: 120px;
  display: inline-block;
  margin-right: 5px;
  position: relative;

  span.remove {
    position: absolute;
    z-index: 1;
    right: 2px;
    top: 1px;

    &:hover {
      cursor: pointer;
    }

    > span.icon {
      background-color: whitesmoke;
      border-radius: 50%;
    }
  }

  img {
    width: 100%;
    height: 100%;
    background-color: #ddd;
  }
}

.repo-selected-images {
  img {
    width: 36px;
    height: 36px;
    margin-right: 1px;
    cursor: pointer;
  }
}

.dropdown-options {
  position: absolute;
  right: 5px;
  top: 5px;
  background-color: aliceblue;
  z-index: 1;
  width: 15px;
  border-radius: 12px;

  &:hover {
    span.icon {
      cursor: pointer;
    }
  }

  span.icon {
    width: 15px;
    color: #363636;
  }

  .selected-dropdown-item {
    &.dropdown-item {
      background-color: #dbdbdb;
    }
  }

  label {
    display: block;
    font-size: calc(0.75rem * 3 / 4);
    font-weight: 600;
    margin-left: 15px;
  }
}

.preview-selected-img-main-screen {
  img {
    width: 40px;
    height: 36px;
    margin-right: 2px;
    background-color: #ddd;
    cursor: pointer;
  }
}

/* Firefox old*/
@-moz-keyframes blink {
  0% {
    opacity: 0.8;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.8;
  }
}

@-webkit-keyframes blink {
  0% {
    opacity: 0.8;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.8;
  }
}

/* Opera and prob css3 final iteration */
@keyframes blink {
  0% {
    opacity: 0.8;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.8;
  }
}

.image-is-loading {
  -moz-animation: blink normal 3s infinite ease-in-out; /* Firefox */
  -webkit-animation: blink normal 3s infinite ease-in-out; /* Webkit */
  animation: blink normal 3s infinite ease-in-out; /* Opera and prob css3 final iteration */
}

</style>
<template>
  <div>
    <div v-if="previouslySelectedImagesList.length && !existingImages" class="mb-2">
      <h4> Previously Selected: {{ 10 - limit }}</h4>
      <div v-for="(img, index) in previouslySelectedImagesList"
           :key="'previouslySelected_' + index"
           class="previously-selected">
        <img :src="getImgUrl(img)" @click="previouslySelectedModal.index = index; previouslySelectedModal.show = true"/>
      </div>
      <b-modal v-model="previouslySelectedModal.show">
        <img :src="getImgUrl(previouslySelectedImagesList[previouslySelectedModal.index])"
             class="is-block mx-auto previously-selected-modal">
      </b-modal>
      <div class="is-clearfix"></div>
    </div>

    <div v-if="uploadedImages.length > 0">
      <div>
        <b>Uploaded images:</b>
      </div>
      <div v-for="(img, index) in uploadedImages" :key="'uploadedImages_' + index" class="modal-images">
        <span @click="removeUploadedImage(index)" class="remove">
          <b-icon icon="close" size="is-small"/>
        </span>
        <img :src="img['url']"/>
      </div>

    </div>

    <div v-if="existingImages">

        <b-field class="mb-1">
          <image-uploader v-model="newUploadedImages" @finished="finishedUploader" :keyword-name="originalKeyword" :margin-left="false"></image-uploader>
        </b-field>
    </div>
    <div v-else>

      <b-field grouped>
        <b-field>
          <b-input
              placeholder="Search..."
              style="max-width: 100%; min-width: 50rem;"
              @keyup.native.enter="search"
              v-model="searchImageQuery"
              type="search"></b-input>
          <p class="control">
            <b-button class="button is-primary" @click="search" :disabled="isLoadingPicassoFreeImages">Search!
            </b-button>
          </p>
        </b-field>
        <b-field class="ml-6">
            or
        </b-field>
        <b-field expanded>
          <image-uploader v-model="newUploadedImages" @finished="finishedUploader"
                          :keyword-name="originalKeyword"></image-uploader>
        </b-field>
      </b-field>

    <div class="mb-1 is-flex is-justify-content-space-between is-align-items-center">
      <div>
        <template v-if="copyPasteEnabled">
          <b-button :disabled="!selectedImages.length" type="is-warning" class="mr-1" size="is-small" @click="copy">Copy
            Selected images
          </b-button>
          <b-button v-if="showPasteBtn" class="mr-1" type="is-warning" size="is-small" @click="paste">Paste Selected
            images
          </b-button>
        </template>
        <template v-if="reposEnabled">
          <b-button type="is-success" size="is-small" class="mr-1" v-for="(repo, index) in userStorage.repos"
                    :key="index" @click="showRepoModal = true; selectedRepoIndex = index; excludedImages = []">
            {{ repo.value.name }} ({{ repo.value.items.length }})
          </b-button>
          <b-modal v-model="showRepoModal" v-if="userStorage.repos[selectedRepoIndex]">
            <div class="card" style="max-width:878px">
              <div class="card-content p-2">
                <div v-if="filteredRepoImages.length" class="is-flex">
                  <b-button size="is-small" type="is-success" class="is-block mb-1 mr-1" @click="useRepo()">Use
                    repository
                  </b-button>
                  <b-button size="is-small" type="is-danger" @click="deleteRepo">Delete repository</b-button>
                </div>
                <div v-else class="notification is-warning is-light">no images found.</div>
                <div v-if="repoSelectedImages.length" class="repo-selected-images mb-1">
                  <b-tooltip label="remove" position="is-right">
                    <img v-for="(img, index) in repoSelectedImages" :key="index" :src="img.url"
                         @click="removeRepoSelectedImage(img)"/>
                  </b-tooltip>
                </div>
                <div v-for="(img, index) in filteredRepoImages" :key="'repoImages_' + index" class="modal-images">
                    <span @click="showDeleteImageModal = true; deletedImageIndex = index" class="remove">
                      <b-icon icon="close" size="is-small"/>
                    </span>
                  <img :src="img['url']"/>
                </div>
                <b-modal
                    v-model="showDeleteImageModal"
                    has-modal-card
                    trap-focus
                    :destroy-on-hide="false"
                    aria-role="dialog"
                    aria-label="Example Modal"
                    close-button-aria-label="Close"
                    aria-modal>
                  <div class="card">
                    <header class="modal-card-head py-3">
                      <p class="modal-card-title">Deleting Image</p>
                    </header>
                    <div class="card-content py-4">
                      Delete the image permanently from the repository, <strong>or</strong> soft delete, to use the
                      other images
                    </div>
                    <footer class="modal-card-foot py-3">
                      <b-button
                          label="Permanent delete"
                          type="is-danger is-small"
                          @click="deletePermanentlyFromRepo()"/>
                      <b-button
                          label="Soft delete"
                          type="is-warning is-small"
                          @click="removeImageFromRepo()"
                      />
                    </footer>
                  </div>
                </b-modal>
              </div>
            </div>
          </b-modal>
        </template>
      </div>
      <div>
        <slot name="left-buttons-box"></slot>
      </div>
    </div>

    Select images: {{ selectedImages.length }}/{{ limit }}
    <div class="preview-selected-img-main-screen">
      <b-tooltip v-if="selectedImages.length" label="remove" position="is-right">
        <img v-for="(img, index) in selectedImages" :key="index" :src="img.url" @click="removeRepoSelectedImage(img)"/>
      </b-tooltip>
    </div>
    <b-loading v-model="isLoadingPicassoFreeImages"></b-loading>
    <div style="max-height: 500px; overflow: auto;">
      <strong>Free Images</strong><br/>
      <div v-for="(img, index) in picassoFreeImagesData"
           v-bind:key="`list-images-free-images-${img.url}-${searchImageQuery}-${counter}-${img.hashUnique}`"
           style="max-width: 300px; height: 250px; float: left; margin: 3px; position: relative">

        <b-dropdown v-if="isSelectedImage(img.hashUnique)" aria-role="menu" class="is-pulled-right dropdown-options"
                    position="is-bottom-left">
          <template #trigger>
            <b-icon icon="dots-vertical" size="is-small"/>
          </template>

          <b-dropdown-item custom aria-role="listitem">
            <b-field label="Create new repository" label-position="on-border">
              <b-input v-model="newRepoName" placeholder="Create new repo" size="is-small"/>
              <p class="control">
                <b-button @click="createRepo" :disabled="!newRepoName.trim()" class="button is-primary" size="is-small"
                          :loading="userStorage.loading">Save
                </b-button>
              </p>
            </b-field>
          </b-dropdown-item>

          <label>Add image to:</label>
          <b-dropdown-item v-for="(repo, dropIndex) in userStorage.repos" :key="dropIndex" @click="addToRepo(repo, img)"
                           :class="highlightSelectedRepo(repo, img) ? 'selected-dropdown-item' : null"
                           aria-role="listitem">
            <span class="ml-1">
              {{ repo.value.name }} ({{ repo.value.items.length }})
            </span>

          </b-dropdown-item>
        </b-dropdown>

        <div @click="selectFreeImage(img, index)" style="">
          <img
              :src="img['url']"
              :class="{
                'image-unselected': true,
                'image-success': img['uploadedIsSuccess'] === true,
                'image-error': img['uploadedIsSuccess'] === false,
                'image-is-loading': img['isLoading'] === true,
              }"
          />
        </div>
      </div>
      <div class="is-clearfix"></div>
      <div v-if="!isLoadingPicassoFreeImages && picassoFreeImagesData.length === 0">
        <b-message
            title="No Free Images Found"
            type="is-danger"
            aria-close-label="Close message">
          No Free images were found, try using different words to find more images. <br/>
          <strong>Tip:</strong> Use synonymous or similar meaning words.
        </b-message>
      </div>
    </div>
    </div>
  </div>
</template>

<script>

import {toolsImageSearcherService} from "@/services";
import vue from "vue";
import {mapState} from "vuex";
import ImageUploader from "@/components/Element/ImageUploader.vue";

export default {
  name: 'ImagePicker',
  components: {ImageUploader},
  props: {
    defaultSearchTerm: {
      type: String,
      required: true,
    },
    originalKeyword: {
      type: String,
      required: true,
    },
    limit: {
      type: Number,
      default: 10
    },
    copyPasteEnabled: {
      type: Boolean,
      default: false,
    },
    reposEnabled: {
      type: Boolean,
      default: true,
    },
    previouslySelectedImagesList: {
      type: Array,
      default: () => []
    },
    existingImages: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      searchImageQuery: '',
      isLoadingPicassoFreeImages: false,
      picassoFreeImagesData: [],
      uploadingFreeImages: [],

      selectedImages: [],

      counter: 0,
      repos: [],
      newRepoName: '',
      showRepoModal: false,
      selectedRepoIndex: 0,
      excludedImages: [],
      showDeleteImageModal: false,
      deletedImageIndex: 0,

      previouslySelectedModal: {
        show: false,
        index: 0,
      },

      uploaderModal: false,
      uploadedImages: [],
      newUploadedImages: [],
    }
  },

  created() {
    this.searchImageQuery = this.defaultSearchTerm;
    if (this.searchImageQuery.length > 0) {
      this.search();
    }

    if (!this.userStorage.repos.length) this.$store.dispatch('userStorage/getRepos', {user: this.$store.state.theme.userId});
  },

  computed: {
    ...mapState(['userStorage']),

    showPasteBtn() {
      return this.$store.state.theme.imagePickerShowPasteBtn
    },

    filteredRepoImages() {
      const newList = [...this.userStorage.repos[this.selectedRepoIndex]['value']['items']]
      if (!this.excludedImages.length) return newList

      this.excludedImages.forEach((ex) => {
        newList.forEach((s, index) => {
          if (ex.hash_unique === s.hash_unique) {
            newList.splice(index, 1)
            return false
          }
        })
        return false
      })
      return newList
    },

    repoSelectedImages() {
      return this.selectedImages.map(i => {
        let s = this.filteredRepoImages.find(r => i.hash_unique === r.hash_unique)
        return s
      }).filter(r => r)
    }
  },

  methods: {
    search() {

      if (this.searchImageQuery === '') {
        return alert('Error: Use write something in order to search');
      }

      this.searchPicassoFreeImages(
          this.searchImageQuery
      )

    },


    searchPicassoFreeImages(keyword) {
      let that = this;

      this.isLoadingPicassoFreeImages = true;
      this.picassoFreeImagesData = [];

      toolsImageSearcherService.searchFreeImages(keyword)
          .then(
              response => {
                that.isLoadingPicassoFreeImages = false;
                that.picassoFreeImagesData = response.data;
              },
              error => {
                this.isLoadingPicassoFreeImages = false;
                alert('An error happens while searching on free images repository.');
                console.error(error);
              }
          );

    },


    selectFreeImage(img, index) {

      let that = this,
          url = img['url'],
          keywordName = this.originalKeyword;

      if (img['isLoading']) {
        alert('Image upload is loading please wait.')
        return;
      }

      if (img['uploadedIsSuccess']) {
        this.removeImage(
            this.picassoFreeImagesData[index]['hashUnique']
        );
        return;
      }

      //prevent selection over the limit
      if (this.selectedImages.length >= this.limit) {
        return alert('You can select maximum ' + this.limit + ' images, please save and move on to the next keyword.');
      }

      this.uploadingFreeImages.push(url);

      vue.set(this.picassoFreeImagesData[index], 'isLoading', true);
      vue.set(this.picassoFreeImagesData[index], 'uploadedIsSuccess', null);

      toolsImageSearcherService.uploadByUrl(
          keywordName,
          url
      ).then(
          response => {
            that.selectImage(response.data);
            vue.set(img, 'hashUnique', response.data['hash_unique']);
            vue.set(this.picassoFreeImagesData[index], 'hashUnique', response.data['hash_unique']);
            delete this.uploadingFreeImages[index];
            vue.set(this.picassoFreeImagesData[index], 'isLoading', false);
            vue.set(this.picassoFreeImagesData[index], 'uploadedIsSuccess', true);
          },
          error => {
            delete this.uploadingFreeImages[index];
            vue.set(this.picassoFreeImagesData[index], 'isLoading', false);
            vue.set(this.picassoFreeImagesData[index], 'uploadedIsSuccess', false);
            alert('An error happens while saving: ' + url);
            console.error(error);
          }
      );
    },

    selectImage(img) {

      let key = img.hash_unique;

      if (this.selectedImages.length >= this.limit) {
        return alert('You can select maximum ' + this.limit + ' images, please save and move on to the next keyword.');
      }

      for (let i in this.selectedImages) {
        if (this.selectedImages[i].hash_unique === key) {
          return this.$buefy.snackbar.open({
            message: 'Image already selected',
          });
        }
      }

      this.selectedImages.push(img);

      this.selectedImagesChanged()
    },

    removeImage(hashUnique) {
      let that = this;

      for (let i in this.selectedImages) {
        if (this.selectedImages[i].hash_unique === hashUnique) {
          vue.delete(this.selectedImages, i);
          break;
        }
      }

      for (let index in this.picassoFreeImagesData) {
        if (this.picassoFreeImagesData[index].hashUnique === hashUnique) {
          this.$nextTick(() => {
            vue.set(that.picassoFreeImagesData[index], 'isLoading', false);
            vue.set(that.picassoFreeImagesData[index], 'uploadedIsSuccess', null);
          })
          break;
        }
      }

      this.selectedImagesChanged()
    },

    selectedImagesChanged() {
      this.$emit('input', {'selectedImages': this.selectedImages, 'uploadedImages': this.newUploadedImages});
    },

    createRepo() {
      if (!this.newRepoName) return;

      this.$store.dispatch('userStorage/createRepo', {
        type: 'IMAGE_REPOSITORY',
        user: this.$store.state.theme.userId,
        value: {name: this.newRepoName.trim(), items: []},
      })

      this.newRepoName = ''
    },

    addToRepo(repo, img) {
      //Avoid image duplication
      const alreadyInRepo = repo.value.items.find(i => i.hash_unique === img.hashUnique)
      if (!alreadyInRepo) {
        repo.value.items.push({url: img.url, hash_unique: img.hashUnique})

        this.$store.dispatch('userStorage/updateRepo', {
          type: 'IMAGE_REPOSITORY',
          user: this.$store.state.theme.userId,
          value: repo.value,
          id: repo.id,
        })
      }
    },

    useRepo() {
      if (this.filteredRepoImages.length > this.limit) {
        this.showRepoModal = false
        return alert('You can select maximum ' + this.limit + ' images (selected repository has ' + this.filteredRepoImages.length + ' imgaes)')
      }
      if (this.selectedImages.length >= this.limit) return this.showRepoModal = false

      //Avoid image duplication
      const _filteredRepoImages = this.filteredRepoImages.map(fri => {
        const isAlreadySelected = this.selectedImages.find(si => si.hash_unique === fri.hash_unique)
        if (!isAlreadySelected) return fri
      }).filter(r => r)
      this.selectedImages.push(..._filteredRepoImages)

      this.selectedImagesChanged()
      this.showRepoModal = false
    },

    highlightSelectedImageInPicasso(list, target) {
      for (let i in list) {
        let alreadyInPicassoList = false
        for (let index in this.picassoFreeImagesData) {
          if (this.picassoFreeImagesData[index].hashUnique === list[i].hash_unique || this.picassoFreeImagesData[index].url === list[i].url) {
            alreadyInPicassoList = true
            this.$nextTick(() => {
              vue.set(target.picassoFreeImagesData[index], 'isLoading', false);
              vue.set(target.picassoFreeImagesData[index], 'uploadedIsSuccess', true);
              vue.set(target.picassoFreeImagesData[index], 'hashUnique', list[i].hash_unique)
            })
            break;
          }
        }
        if (!alreadyInPicassoList) {
          this.picassoFreeImagesData =
              [
                {
                  hashUnique: list[i].hash_unique,
                  url: list[i].url,
                  isLoading: false,
                  uploadedIsSuccess: true,
                },
                ...this.picassoFreeImagesData
              ]
        }
      }
    },

    deleteRepo() {
      if (confirm(`Are you sure you want to delete ${this.userStorage.repos[this.selectedRepoIndex].value.name}!`)) {
        this.$store.dispatch('userStorage/deleteRepo', {
          id: this.userStorage.repos[this.selectedRepoIndex].id,
        })
        this.showRepoModal = false
      }
    },

    isSelectedImage(hash) {
      return this.selectedImages.find(i => i.hash_unique === hash)
    },

    highlightSelectedRepo(repo, img) {
      return repo.value.items.find(i => i.hash_unique === img.hashUnique)
    },

    removeImageFromRepo() {
      this.excludedImages.push(this.filteredRepoImages[this.deletedImageIndex])
      this.showDeleteImageModal = false
    },

    deletePermanentlyFromRepo() {
      const img = this.filteredRepoImages[this.deletedImageIndex]

      //do soft del
      this.removeRepoSelectedImage(img)
      this.removeImageFromRepo(img)
      this.showDeleteImageModal = false

      //permanent del
      let {name, items} = this.userStorage.repos[this.selectedRepoIndex].value
      items = items.map(i => {
        if (i.hash_unique !== img.hash_unique) return i
      }).filter(r => r)

      const value = {name, items}
      this.$store.dispatch('userStorage/updateRepo', {
        type: 'IMAGE_REPOSITORY',
        user: this.$store.state.theme.userId,
        value,
        id: this.userStorage.repos[this.selectedRepoIndex].id,
      })
    },

    removeRepoSelectedImage(img) {
      let imageIndex = this.findSelectedImageIndex(img);
      if (!isNaN(imageIndex)) this.selectedImages.splice(imageIndex, 1)

      //remove from picasso images
      const self = this
      for (let index in this.picassoFreeImagesData) {
        if (this.picassoFreeImagesData[index].hashUnique === img.hash_unique) {
          this.$nextTick(() => {
            vue.set(self.picassoFreeImagesData[index], 'isLoading', false);
            vue.set(self.picassoFreeImagesData[index], 'uploadedIsSuccess', null);
          })
          break;
        }
      }
    },

    findSelectedImageIndex(img) {
      for (let i = 0; i < this.selectedImages.length; i++) {
        if (this.selectedImages[i]['hash_unique'] === img.hash_unique) return i;
      }
    },

    copy() {
      localStorage.setItem('images_clipboard', '[]')
      localStorage.setItem('images_clipboard', JSON.stringify(this.selectedImages))
      this.$store.commit('theme/set', ['imagePickerShowPasteBtn', true])
      this.internalPasteBtnControl = false

      this.$buefy.toast.open({
        duration: 900,
        message: "Copied!",
        type: 'is-success is-small',
      })
    },

    paste() {
      const images_clipboard = JSON.parse(localStorage.getItem('images_clipboard'))
      if (!images_clipboard) return;

      if (images_clipboard.length > this.limit) {
        return alert('You can select maximum ' + this.limit + ' images (copied images: ' + images_clipboard.length + ')')
      }

      const self = this
      for (let ic in images_clipboard) {
        const isInSelectedImages = self.selectedImages.find(si => si.hash_unique === images_clipboard[ic].hash_unique)
        if (!isInSelectedImages) self.selectedImages.push(images_clipboard[ic])
      }

      this.highlightSelectedImageInPicasso(self.selectedImages, this)

      //rest the clipboard //no need, cuz in case i wanna paste it more than once, but i will leave it here
      // localStorage.setItem('images_clipboard', null)
      //this.$store.commit('theme/set', ['imagePickerShowPasteBtn', false])
      this.selectedImagesChanged()

      this.$buefy.toast.open({
        duration: 900,
        message: "Pasted!",
        type: 'is-success is-small',
      })
    },

    getImgUrl(val, mode = 'full_image') {  // size => full_image or thumbnail
      return `https://api.mozart.cloud.spectretechnology.io/api/proxy/picasso/image/image-opener-public/?mode=${mode}&image_hash_unique=${val['image_hash_unique']}`;
    },

    toggleUploaderModal() {
      this.uploaderModal = !this.uploaderModal;
    },

    removeUploadedImage(index){
      vue.delete(this.uploadedImages, index);
    },

    finishedUploader(){
      let len = this.uploadedImages.length;

      for(let i in this.newUploadedImages){
        vue.set(this.uploadedImages, len + parseInt(i), this.newUploadedImages[i]);
      }

      this.selectedImagesChanged();
      this.toggleUploaderModal();
    }

  }
}

</script>
